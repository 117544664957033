<template>
  <div class="logincbox">
    <div class="title ta-c">找回密码</div>
    <div class="ftit mb-50 ta-c">请使用账号绑定的手机号进行验证并重置密码</div>
    <el-form :model="formModel" class="demo_ruleForm mb-46" ref="ruleFormRef">
      <el-form-item :rules="[{ validator: validPhoneData, trigger: 'blur' }]" prop="phone">
        <el-input v-model="formModel.phone" placeholder="手机号" :disabled="state.confirm" />
      </el-form-item>
      <template v-if="state.confirm">
        <el-form-item :rules="[{ validator: validPasswordData, trigger: 'blur' }]" prop="password">
            <el-input v-model="formModel.password" placeholder="8-16位数字,字母和特殊字符任意两种组合密码" show-password />
          </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请输入验证码' }, { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }]" prop="vcode" class="pt-r">
          <verificationCode ref="verificationCodeRef" class="pt-a vcode" :CSStype="1" @Emit="vCodeEmit"/>
          <el-input v-model="formModel.vcode" placeholder="请输入验证码" maxlength="6" />
        </el-form-item>
      </template>
    </el-form>
    <oabutton v-if="state.confirm" v-model:loading="state.loading" class="mb-30" width='462' height='48' title="确定修改" CSStype=2
      style="border-radius: 4px;font-size: 18px;font-weight: 500;" @buttonclick="submitForm(2)"></oabutton>
    <oabutton v-else v-model:loading="state.loading" class="mb-30" width='462' height='48' title="下一步" CSStype=2
      style="border-radius: 4px;font-size: 18px;font-weight: 500;" @buttonclick="submitForm(1)"></oabutton>
    <div class="dp-f alreadyhave jc-c">
      <div class="cu-p" @click="emit('setKey', 'login')">使用已有账号登录</div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm.js";
import { encryption } from '@/utils/encryption/encryption.js'
import { validPhone, validEmail,validPassword } from "@/utils/server/validate.js";
import qs from "qs";
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
const state = reactive({
  loading: false,//loading显示
  confirm: false,//是否通过手机号校验
})
const ruleFormRef = ref()//表单ref
const verificationCodeRef = ref()//验证码组件ref
const emit = defineEmits(['setKey'])
// 校验密码格式
const validPasswordData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    if (validPassword(value)) {
      callback()
    } else
      callback(new Error('密码格式错误(8-16位数字,字母和特殊字符任意两种组合密码)'))
  }
}
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      httpToken({
        method: "post",
        url: '/admin/app/checkPhone',
        data: qs.stringify({
          'phone': value,
          'type': 1
        })
      }).then(res => {
        // res.data 1手机号已存在 2手机号不存在
        if (res.data == 1) {
          callback()
        } else {
          callback(new Error(res.msg))
        }
      }).catch(() => {
        callback(new Error('校验手机号接口请求失败'))
      })
    } else
      callback(new Error('手机号格式错误'))
  }
}
const formModel = reactive({
  password: '',//密码
  phone: '',//手机号
  vcode: '',//验证码
})
// 验证码返回
const vCodeEmit = (() => {
  httpToken({
    method: "post",
    url: '/admin/app/sms',
    data: {
      'phone': formModel.phone,
      'exist': true,
      'appid': '1',
      'type': 3,
    }
  }).then(res => {
    unref(verificationCodeRef).send()
  })
})
// 表单提交
const submitForm = async (type) => {
  state.loading = true
  const form = unref(ruleFormRef)
  console.log('form11',form,type)
  if (!form) {
    state.loading = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      console.log('通过')
      if (type == 1) {
        state.loading = false
        // 通过正则校验 手机号校验 再继续下一步
        state.confirm = true
      }else{
        const user = encryption({
          data: { password: formModel.password },
          key: 'thanks,pig4cloud',
          param: ['password']
        })
        httpToken({
          method: "post",
          url: '/admin/sys-user/retrievePassword',
          data: {
            'phone': formModel.phone,
            'newPassword': user.password,
            'code': formModel.vcode,
          }
        }).then(res => {
          console.log('res',res)
          if (res.code == 200) {
            handleMessage('找回密码成功请直接登录', 'success')
            emit('setKey', 'login')
            state.loading = false
          }
        }).catch(()=>{
          state.loading = false
        })
      }
    } else {
      state.loading = false
    }
  })
}
</script>
<style lang="scss" scoped >
.logincbox {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333330;

  .title {
    margin-bottom: 22px;
  }

  .ftit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .demo_ruleForm {
    ::v-deep .el-input {
      width: 460px;
      height: 54px;
      border: 1px solid #E8E8E8;

      .el-input__wrapper {
        box-shadow: none;
      }

    }

    ::v-deep .el-input.is-disabled {
      .el-input__wrapper {
        background-color: rgba(249, 249, 249, 1) !important;
        border: 1px solid #E8E8E8 !important;
        border-radius: 0;

        .el-input__inner {
          -webkit-text-fill-color: #999999 !important;
        }
      }
    }
  }

  .alreadyhave {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #EEC774;
  }
}

::v-deep .el-input__wrapper {
  padding: 1px 15px;
}

.vcode {
  z-index: 10;
  right: 18px;
}</style>